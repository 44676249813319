import React from "react";
import { motion } from "framer-motion";
import transition from "../transition";

function About() {
  return (
    <section className="text-center w-full">
      <h3 className="text-[#FD4F29] font-bold text-3xl text-center">Roadmap</h3>
      <div className="max-w-4xl mx-auto grid grid-cols-1 lg:grid-cols-2 lg:gap-8 mt-6">
        <ul>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm">
              Implement support for Solana
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Extend support to Pancake Swap and Uniswap
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Allow users to create "burner" wallets through the sniper
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Extend support to non-native pairs
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Introduce Pinksale presale sniping
            </span>
          </li>
        </ul>
        <ul>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm">
              Add user-controlled Telegram scraper
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Develop copy-trading mechanisms
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Improve bot efficiency
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Introduce more anti-scam features
            </span>
          </li>
          <li className="text-left flex items-center gap-2">
            <svg
              className="flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="60"
              viewBox="0 0 15 60"
              fill="none"
            >
              <path d="M8 0V60" stroke="#FD4F29" stroke-dasharray="2 2" />
              <ellipse
                cx="7.5"
                cy="31.3195"
                rx="7.5"
                ry="7.62712"
                fill="#FDB929"
              />
            </svg>
            <span className="inline-block max-w-sm text-left">
              Allow simultaneous multi-wallet sniping and trading
            </span>
          </li>
        </ul>
      </div>
      <h3 className="text-[#FD4F29] font-bold text-3xl text-center mt-16">
        Links
      </h3>
      <div className="max-w-4xl flex flex-col lg:flex-row items-center justify-center mx-auto mt-6 gap-6 lg:gap-10">
        <a
          href="https://t.me/DuckGun_TG"
          target="_blank"
          rel="noopener noreferrer"
          className="h-[129px] w-[170px] flex flex-col gap-5 justify-center items-center bg-[#2F88F0] text-white p-6 text-2xl font-extrabold border-[1px] rounded-xl border-black transition-all hover:bg-[#FDB929]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
          >
            <path
              d="M30 0.92105L25.2546 24.9176C25.2546 24.9176 24.5906 26.5814 22.7667 25.7834L11.8178 17.3628L11.767 17.3379C13.2459 16.0059 24.7143 5.6629 25.2155 5.19407C25.9915 4.46797 25.5098 4.0357 24.6089 4.5842L7.66862 15.3751L1.13311 13.1694C1.13311 13.1694 0.104617 12.8024 0.00567291 12.0045C-0.0945729 11.2053 1.16696 10.773 1.16696 10.773L27.8102 0.288979C27.8102 0.288979 30 -0.676107 30 0.92105Z"
              fill="white"
            />
          </svg>
          <span>Telegram</span>
        </a>
        <a
          href="https://twitter.com/DuckGun_X"
          target="_blank"
          rel="noopener noreferrer"
          className="h-[129px] w-[170px] flex flex-col gap-5 justify-center items-center bg-[#2F88F0] text-white p-6 text-2xl font-extrabold border-[1px] rounded-xl border-black transition-all hover:bg-[#FDB929]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
          >
            <path
              d="M30 3.54486C28.9286 4.02563 27.7041 4.34615 26.4796 4.50641C27.7041 3.70513 28.7755 2.42308 29.2347 0.980774C28.0102 1.78206 26.7857 2.26282 25.2551 2.58334C24.1837 1.30129 22.5 0.5 20.8163 0.5C17.449 0.5 14.6939 3.38461 14.6939 6.91025C14.6939 7.39102 14.6939 7.87178 14.8469 8.35255C9.79592 8.03204 5.20407 5.46794 2.14285 1.62179C1.68366 2.58333 1.37754 3.70512 1.37754 4.82692C1.37754 7.07051 2.44898 8.99359 4.13265 10.1154C3.06122 10.1154 2.14285 9.79487 1.37754 9.3141C1.37754 12.359 3.5204 15.0833 6.2755 15.5641C5.81632 15.7243 5.20408 15.7244 4.59184 15.7244C4.13266 15.7244 3.82653 15.7243 3.36734 15.5641C4.13265 18.1282 6.42857 20.0513 9.18368 20.0513C7.04082 21.8141 4.43878 22.7756 1.53061 22.7756C1.07143 22.7756 0.612245 22.7756 0 22.6154C2.7551 24.3782 5.96939 25.5 9.4898 25.5C20.8163 25.5 26.9388 15.7243 26.9388 7.23076V6.42948C28.1633 5.94871 29.2347 4.82691 30 3.54486Z"
              fill="white"
            />
          </svg>
          <span>Twitter</span>
        </a>
        <a className="h-[129px] w-[170px] flex flex-col gap-5 justify-center items-center bg-[#2F88F0] text-white p-6 text-2xl font-extrabold border-[1px] rounded-xl border-black transition-all hover:bg-[#FDB929]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M6.21094 30H23.7891C25.243 30 26.4258 28.8172 26.4258 27.3633V8.78906H20.2734C18.8195 8.78906 17.6367 7.60629 17.6367 6.15234V0H6.21094C4.75699 0 3.57422 1.18277 3.57422 2.63672V27.3633C3.57422 28.8172 4.75699 30 6.21094 30ZM9.72656 12.3633H20.2734C20.7592 12.3633 21.1523 12.7564 21.1523 13.2422C21.1523 13.728 20.7592 14.1211 20.2734 14.1211H9.72656C9.24076 14.1211 8.84766 13.728 8.84766 13.2422C8.84766 12.7564 9.24076 12.3633 9.72656 12.3633ZM9.72656 15.8789H20.2734C20.7592 15.8789 21.1523 16.272 21.1523 16.7578C21.1523 17.2436 20.7592 17.6367 20.2734 17.6367H9.72656C9.24076 17.6367 8.84766 17.2436 8.84766 16.7578C8.84766 16.272 9.24076 15.8789 9.72656 15.8789ZM9.72656 19.3945H20.2734C20.7592 19.3945 21.1523 19.7876 21.1523 20.2734C21.1523 20.7592 20.7592 21.1523 20.2734 21.1523H9.72656C9.24076 21.1523 8.84766 20.7592 8.84766 20.2734C8.84766 19.7876 9.24076 19.3945 9.72656 19.3945ZM9.72656 22.9102H16.7578C17.2436 22.9102 17.6367 23.3033 17.6367 23.7891C17.6367 24.2749 17.2436 24.668 16.7578 24.668H9.72656C9.24076 24.668 8.84766 24.2749 8.84766 23.7891C8.84766 23.3033 9.24076 22.9102 9.72656 22.9102Z"
              fill="white"
            />
            <path
              d="M20.2734 7.03116H25.9108L19.3945 0.514893V6.15226C19.3945 6.63718 19.7885 7.03116 20.2734 7.03116Z"
              fill="white"
            />
          </svg>
          <span>Document</span>
        </a>
      </div>

      <span className="block text-center mt-20 text-[#8B8B8B]">
        © Design by Duckgun
      </span>
    </section>
  );
}

export default transition(About);
