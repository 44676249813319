import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import Features from "./Features";
import About from "./About";
import { AnimatePresence } from "framer-motion";
function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
