import { Link, NavLink, BrowserRouter as Router } from "react-router-dom";

import logo from "./img/logo.svg";
import AnimatedRoutes from "./pages/AnimatedRoutes";

import "./style.css";
import Button from "./components/button";

function App() {
  return (
    <Router>
      <div className="app m-1 lg:!m-7 min-h-screen">
        <div className="min-h-screen border-[2px] lg:border-[3px] border-black rounded-3xl p-2 lg:!p-8 bg-[#F74B37]">
          <div className="min-h-screen flex bg-white rounded-2xl px-2 py-4 lg:!p-5 border-[2px] lg:border-[3px] border-black">
            <div className="container mx-auto flex flex-col relative gap-10">
              <div className="hidden lg:block absolute right-0 top-1/2 -translate-y-1/2">
                <ul className="flex flex-col items-center gap-4">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-[#FDB929]" : "bg-[#DCD7CD]"
                        } block rounded-full w-4 h-4 lg:w-5 lg:h-5`
                      }
                      to="/"
                    ></NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-[#FDB929]" : "bg-[#DCD7CD]"
                        } block rounded-full w-4 h-4 lg:w-5 lg:h-5`
                      }
                      to="/about"
                    ></NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-[#FDB929]" : "bg-[#DCD7CD]"
                        } block rounded-full w-4 h-4 lg:w-5 lg:h-5`
                      }
                      to="/features"
                    ></NavLink>
                  </li>
                </ul>
              </div>
              <header className="flex flex-col lg:flex-row gap-8 lg:gap-2 items-center justify-between">
                <div className="flex items-center gap-6">
                  <NavLink to="/" className="hidden lg:block w-28">
                    <img src={logo} alt="" />
                  </NavLink>
                  <ul className="flex items-center gap-5 lg:gap-8">
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          `pb-2 border-b-[2px] ${
                            isActive
                              ? "border-b-[#FDB929]"
                              : "border-b-transparent"
                          }`
                        }
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <span className="block w-[10px] h-[10px] rounded-full bg-[#FDB929]"></span>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          `pb-2 border-b-[2px] ${
                            isActive
                              ? "border-b-[#FDB929]"
                              : "border-b-transparent"
                          }`
                        }
                        to="/about"
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <span className="block w-[10px] h-[10px] rounded-full bg-[#FDB929]"></span>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          `pb-2 border-b-[2px] ${
                            isActive
                              ? "border-b-[#FDB929]"
                              : "border-b-transparent"
                          }`
                        }
                        to="/features"
                      >
                        Features
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <Button text="Telegram" link="https://t.me/DuckGun_TG" blue />
              </header>
              <main className="lg:p-10 flex-1 flex">
                <AnimatedRoutes />
              </main>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
