import React, { useState } from "react";
import transition from "../transition";
import ftImg from "../img/ft.png";
import modalImg from "../img/trade-modal.png";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function Features() {
  const [isTradeModal, setIsTradeModal] = useState(false);
  const [isSniperModal, setIsSniperModal] = useState(false);
  const [isCopyTradModal, setIsCopyTradeModal] = useState(false);

  const openTradeModal = () => setIsTradeModal(true);
  const openSniperModal = () => setIsSniperModal(true);
  const openCopyTradeModal = () => setIsCopyTradeModal(true);

  const closeTradeModal = () => setIsTradeModal(false);
  const closeSniperModal = () => setIsSniperModal(false);
  const closeCopyTradeModal = () => setIsCopyTradeModal(false);

  return (
    <section className="flex flex-1 flex-col lg:flex-row justify-center items-center gap-20">
      <div className="max-w-[250px] lg:max-w-md flex-shrink-0">
        <img src={ftImg} alt="" />
      </div>
      <div className="max-w-2xl flex flex-col gap-6">
        <div>
          <h3 className="text-3xl text-[#FD4F29] font-bold">Trades</h3>
          <p className="my-3">
            Unlock a seamless and powerful trading experience with Duckgun's
            Trades feature. Built on the Solana blockchain, this functionality
            is designed to provide you with unparalleled control over your
            cryptocurrency trades
          </p>
          <span
            className="group cursor-pointer inline-flex border-[#fd4f29] border-[1px] rounded-full px-8 py-2.5 transition-all hover:bg-[#fd4f29]"
            onClick={openTradeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 512 512"
              className="transition-all fill-[#fd4f29] group-hover:fill-white"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </span>
        </div>
        <div>
          <h3 className="text-3xl text-[#FD4F29] font-bold">Sniper Bot</h3>
          <p className="my-3">
            Experience precision trading like never before with Duckgun's Sniper
            Bot. This powerful tool, operating on the Solana chain, is
            engineered to give you a competitive edge in the dynamic world of
            cryptocurrency.
          </p>
          <span
            onClick={openSniperModal}
            className="group cursor-pointer inline-flex border-[#fd4f29] border-[1px] rounded-full px-8 py-2.5 transition-all hover:bg-[#fd4f29]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 512 512"
              className="transition-all fill-[#fd4f29] group-hover:fill-white"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </span>
        </div>
        <div>
          <h3 className="text-3xl text-[#FD4F29] font-bold">Copy Trade</h3>
          <p className="my-3">
            Experience the power of smart trading with Duckgun's Copy Trade
            feature. This innovative functionality allows you to harness the
            expertise of successful traders and replicate their strategies
            effortlessly.
          </p>
          <span
            onClick={openCopyTradeModal}
            className="group cursor-pointer inline-flex border-[#fd4f29] border-[1px] rounded-full px-8 py-2.5 transition-all hover:bg-[#fd4f29]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 512 512"
              className="transition-all fill-[#fd4f29] group-hover:fill-white"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </span>
        </div>
      </div>

      <Modal open={isTradeModal} onClose={closeTradeModal}>
        <div className="py-3">
          <h2 className="text-center text-4xl uppercase font-bold text-[#FD4F29]">
            Trades
          </h2>
          <p className="mt-3">
            Unlock a seamless and powerful trading experience with Duckgun's
            Trades feature. Built on the Solana blockchain, this functionality
            is designed to provide you with unparalleled control over your
            cryptocurrency trades. Here's what makes Duckgun's Trades stand out:
          </p>
          <div className="mx-auto max-w-xs my-8">
            <img src={modalImg} alt="" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                trade fast
              </h3>
              <p className="mt-3">
                Execute trades with unmatched speed on the Solana blockchain,
                ensuring that you are always ahead of market movements.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Customizable Strategies
              </h3>
              <p className="mt-3">
                Tailor your trading strategies to match your unique preferences
                and risk tolerance. Duckgun empowers you to take charge of your
                trading journey.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Real-Time Market Insights
              </h3>
              <p className="mt-3">
                Stay informed with up-to-the-minute market data and insights.
                Duckgun ensures that you have the latest information at your
                fingertips, allowing you to make informed decisions.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Secure Transactions
              </h3>
              <p className="mt-3">
                Your security is our top priority. Duckgun employs advanced
                security measures to safeguard your transactions on the Solana
                blockchain, providing you with peace of mind.
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={isSniperModal} onClose={closeSniperModal}>
        <div className="py-3">
          <h2 className="text-center text-4xl uppercase font-bold text-[#FD4F29]">
            Sniper Bot
          </h2>
          <p className="mt-3">
            Experience precision trading like never before with Duckgun's Sniper
            Bot. This powerful tool, operating on the Solana chain, is
            engineered to give you a competitive edge in the dynamic world of
            cryptocurrency. Here's why Sniper Bot is a game-changer:
          </p>
          <div className="mx-auto max-w-xs my-8">
            <img src={modalImg} alt="" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Split-Second Decision Making
              </h3>
              <p className="mt-3">
                Activate Sniper Bot to capitalize on market opportunities with
                split-second precision. Duckgun's Sniper Bot scans the market
                swiftly and executes trades at optimal moments.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Risk Management
              </h3>
              <p className="mt-3">
                Set custom parameters to manage risk effectively. Whether you're
                a conservative or aggressive trader, Sniper Bot allows you to
                fine-tune your strategy for optimal results.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Advanced Algorithmic Trading
              </h3>
              <p className="mt-3">
                Utilizing cutting-edge algorithms, Sniper Bot analyzes market
                trends and identifies potential profitable trades. Let Duckgun's
                Sniper Bot handle the complexities while you enjoy the results.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                24/7 Availability
              </h3>
              <p className="mt-3">
                The cryptocurrency market never sleeps, and neither does
                Duckgun's Sniper Bot. Take advantage of market movements around
                the clock with our automated and reliable trading companion.
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={isCopyTradModal} onClose={closeCopyTradeModal}>
        <div className="py-3">
          <h2 className="text-center text-4xl uppercase font-bold text-[#FD4F29]">
            Copy Trade
          </h2>
          <p className="mt-3">
            Experience the power of smart trading with Duckgun's Copy Trade
            feature. This innovative functionality allows you to harness the
            expertise of successful traders and replicate their strategies
            effortlessly. Here's why Copy Trade with Duckgun is a game-changer:
          </p>
          <div className="mx-auto max-w-xs my-8">
            <img src={modalImg} alt="" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Automatic Replication
              </h3>
              <p className="mt-3">
                With Copy Trade, you can automatically replicate the trades of
                experienced and successful traders on the Solana blockchain. No
                need for manual execution – let Duckgun do the work for you.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Diversify Your Portfolio
              </h3>
              <p className="mt-3">
                Gain access to a diverse range of trading strategies by copying
                trades from a variety of skilled traders. Diversification is key
                to managing risk and maximizing potential returns.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Real-Time Updates
              </h3>
              <p className="mt-3">
                Stay in the loop with real-time updates on copied trades.
                Duckgun ensures that you are always informed about the latest
                actions taken by the traders you are following.
              </p>
            </div>
            <div className="bg-[#FDB929] border-[1px] border-black rounded-2xl p-4">
              <h3 className="capitalize text-xl pb-2 border-b-[1px] border-b-black">
                Customizable Settings
              </h3>
              <p className="mt-3">
                Tailor the Copy Trade feature to suit your risk tolerance and
                investment goals. Adjust settings to control the size of each
                copied trade and maintain flexibility in your trading approach.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default transition(Features);
