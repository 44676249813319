import React from "react";
import transition from "../transition";
import Button from "../components/button";
import homeImg from "../img/home.svg";
function Home() {
  return (
    <section className="flex flex-col-reverse lg:flex-row justify-end lg:justify-between items-center gap-10">
      <div className="max-w-xl">
        <h1 className="font-bold text-5xl">Welcome to Duckgun!</h1>
        <h3 className="text-[#FD4F29] font-medium text-xl mt-2">
          Telegram Trading & Sniper Bot
        </h3>
        <p className="mt-3">
          Explore the sophisticated and challenging world of the financial
          market with Duckgun - your loyal companion for investors of all
          levels, from beginners to experienced professionals. Duckgun is not
          just an ordinary Telegram bot but also a source of motivation and a
          tool to help you navigate through every aspect of the market.
        </p>
        <Button className="mt-5" text="Get Started Now" />
      </div>
      <div className="max-w-[250px] lg:max-w-md">
        <img src={homeImg} alt="" />
      </div>
    </section>
  );
}

export default transition(Home);
